import logo from './logo.svg';
import './App.css';
import Homepage from './components/Homepage/Home'
import Header from './components/Header/header'

function App() {
  return (
    <>
      <Header/>
      <Homepage/>
    </>
    
  );
}

export default App;
